*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  height: 100%;
  font-weight: 400;
  font-size: 1.7rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.6;
  margin: 0;
}
#root {
  height: 100%;
}

.hide__scrollbar {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.empty {
  opacity: 0;
}

.flex {
  flex: 1;
}
