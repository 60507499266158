.issues__screen {
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  align-items: center;

  $primary-color: #074e6e;
  $secondary-color: #6c757d;
  $light-color: #f8f9fa;

  &__content {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    text-align: left;
    flex: 1;
    &__btn {
      height: 3rem !important;
      width: 8rem !important;
    }
    &__search {
      display: flex;
      justify-content: center;
    }
    &__video__container {
      height: 20rem;
      overflow: hidden;
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      position: relative;

      &__edit {
        position: absolute;
        bottom: 0;
        left: 50%;
        color: white;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        background-color: #0a658e;
        transform: translateX(-50%);
        text-decoration: underline;
        cursor: pointer;
      }

      &__image {
        object-fit: cover;
        object-position: top;
        display: block;
        height: 100%;
        width: 100%;

        &__input {
          display: none;
        }
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-title {
      font-family: 'Lato-Heavy';
      font-size: 4rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    &-search {
      margin-left: 3rem;
      margin-top: 2rem;
      max-width: 50rem;
    }

    &-iconContainer {
      margin-left: auto;
      justify-content: center;
      align-items: center;
    }
    &-icon {
      height: 5rem;
      width: 5rem;
      align-self: center;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }

  .checkbox_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    label {
      color: white;
    }
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: inline-block;
      cursor: pointer;
      width: 25px;
      height: 25px;
      background-clip: content-box;
      border: 1.5px solid #189ec1;
      border-radius: 6px;
      background-color: transparent;
      // margin-left: 15px;
      // margin-right: 15px;

      &:checked {
        background-color: #189ec1;
        border: 1.5px solid #8ccfe0;
      }

      &:focus {
        outline: none !important;
      }
    }
  }
  .select-container {
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // min-width: 440px;
    // max-width: 440px;
    margin-top: 20px;

    &:last-child {
      margin-right: 0;
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-width: 5px 5px 0;
      border-color: $secondary-color transparent;
      border-style: solid;
    }

    &.is-open {
      &:after {
        border-width: 0 5px 5px;
        border-color: transparent $secondary-color;
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    span {
      color: #f8f9fa;
    }

    select {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid $secondary-color;
      border-radius: 3px;
      background-color: $light-color;
      color: $secondary-color;
      padding: 10px;
      width: 350px;
      cursor: pointer;
    }
  }
}
.video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .iconContainer {
    position: absolute;
    display: flex;
    align-self: center;
    z-index: 100;
    top: 0;
    right: 50px;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
}

.property-dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;

  select {
    width: 100%;
    padding: 0.8rem;
    font-size: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    background-color: white;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    margin-top: 0.2rem;
    color: #333;
  }

  select:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }

  /* Hide the default arrow icon in IE and Edge */
  select::-ms-expand {
    display: none;
  }

  /* Style the dropdown arrow */
  select:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    pointer-events: none;
    border-style: solid;
    border-width: 0.3rem 0.3rem 0 0;
    border-color: #555 transparent transparent transparent;
  }
}
