.cluster__screen {
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  overflow: scroll;

  &__createContainer {
    margin-top: 2rem;
    margin-left: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 50rem;
    align-self: flex-start;
  }

  &__clustersContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    gap: 20px;
    &__cluster {
      width: 350px;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.columns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  width: 100%;
}
.rowContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

  border-color: #189ec1;
  border-style: solid;
  border-radius: 10px;
  border-width: 1.5px;
  padding: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  min-height: 250px;
  min-width: 350px;
}

.propertyColumn {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  border-color: #189ec1;
  border-style: solid;
  border-radius: 10px;
  border-width: 1.5px;
  padding: 20px;
  height: fit-content;
  margin-left: 20px;
  margin-bottom: 20px;
  min-width: 350px;
}

.list {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient(135deg, #189ec1 10%, #0a658e 90%);
  border-radius: 5px;
  padding: 4px;
  margin-top: 4px;

  &:hover {
    color: #fff;
    background: linear-gradient(135deg, #189ec1 100%, #0a658e 0%);
  }
}
.clone {
  transform: none !important;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient(135deg, #189ec1 100%, #0a658e 0%);
  border-radius: 5px;
  padding: 4px;
  margin-top: 4px;
}

.iconContainer {
  position: absolute;
  top: 5px;
  cursor: pointer;
  right: 5px;
  &:hover {
    fill: red;
  }
}

.iconItemContainer {
  position: absolute;
  right: 5px;
  top: 8px;
  z-index: 100;
  fill: white;
  cursor: pointer;
}
