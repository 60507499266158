.navbar {
  background: #074e6e;
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-toggle {
    background: white;
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
      height: 2.4rem;
      cursor: pointer;
    }
  }
}

.menu-bars {
  margin-left: 3.2rem;
  background: none;
  height: 2.4rem;
  cursor: pointer;
}

.nav-menu {
  background: white;
  width: 25rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 15px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &-items {
    width: 100%;
  }

  span {
    margin-left: 1.6rem;
  }
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text-marketplace {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 12rem;
  padding: 0.8rem 0 0.8rem 1.6rem;
  .header-menu {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    font-size: 1.8rem;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1.6rem;
    border-radius: 4px;
    font-family: 'Lato-Medium';
  }
  .sub-menus {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 1.8rem;
    width: 95%;
    padding-left: 1rem;
    height: 100%;
  }
  .sub-menu {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-left: 2rem;
    text-decoration: none;
  }
  a {
    text-decoration: none;
    font-size: 1.8rem;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1.6rem;
    border-radius: 4px;
    color: #074e6e;
    font-family: 'Lato-Medium';

    &:hover {
      background-color: #074e6e30;
    }
  }
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.8rem 0 0.8rem 1.6rem;
  list-style: none;
  height: 6rem;
  a {
    text-decoration: none;
    font-size: 1.8rem;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1.6rem;
    border-radius: 4px;
    color: #074e6e;
    font-family: 'Lato-Medium';

    &:hover {
      background-color: #074e6e30;
    }
  }
}

.dropdownToggler {
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-word;
  cursor: pointer;
}

.userEmail {
  font-size: 2rem;
  color: white;
  font-weight: 500;
}

.dropdownWrapper {
  position: absolute;
  top: 1rem;
  left: -7.5rem;
  border-radius: 0.5rem;
  width: 15.5rem;
  background: white;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.dropdownButton {
  background: transparent;
  width: 100%;
  padding: 1rem 0;
  font-size: 1.4rem;
  border: none;
  transition: background-color 0.4s, color 0.4s;
  font-weight: 500;
  font-family: 'Lato-Medium';
  cursor: pointer;
}

.modal__content {
  display: flex;
  flex-direction: column;

  &-text {
    text-align: center;
    color: #f4f8fb;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    font-family: 'Lato-Regular';
  }

  &__loader__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &-text {
      text-align: center;
      color: #f4f8fb;
      font-size: 1.6rem;
      padding-right: 2rem;
      font-family: 'Lato-Regular';
    }

    &-small-text {
      text-align: center;
      color: #f4f8fb70;
      font-size: 1.3rem;
      font-family: 'Lato-Regular';
    }

    &-loader {
      width: 5rem;
      height: 5rem;
      margin: unset !important;
    }
  }

  &__button__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding-top: 2rem;

    &-btn {
      height: 3.2rem;
      color: #f4f8fb;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
}
