.login__screen {
  background: linear-gradient(135deg, #189ec1 0%, #0a658e 100%);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__form {
    max-width: 50rem;
    min-width: 30rem;
    max-height: 70rem;
    width: 30%;
    height: 60%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 0.5rem;

    &__input__container {
      width: 100%;
    }

    &__title {
      color: #0b6a92;
      font-family: 'PTSerif-Bold';
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 3.2rem;
    }

    &__error {
      color: #0b6a9270;
      font-size: 1.6rem;
      line-height: 1.6rem;
      margin-left: 1.2rem;
      height: 30px;
    }
  }
}
