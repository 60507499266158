.property__screen {
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  overflow: scroll;

  &__content {
    width: 90%;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__container {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      gap: 2rem;

      &__row__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__icon_arrow {
        height: 4rem;
        width: 4rem;
        transform: rotate(-90deg);
      }

      &__icon {
        height: 4rem;
        width: 4rem;
      }

      &__button {
        margin-top: 2rem;
        cursor: pointer;
        min-height: 16.1rem;
        flex: 1;
        border-radius: 0.5rem;
        padding: 1rem;
        background: linear-gradient(180deg, #e3eef3 0%, #c0dae6 100%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__sync {
          margin-top: 2rem;
          cursor: pointer;
          min-height: 8.1rem !important;
          flex: 1;
          border-radius: 0.5rem;
          padding: 1rem;
          background: linear-gradient(180deg, #e3eef3 0%, #c0dae6 100%);
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &__button__content {
        display: flex;
        align-items: center;
        &__justify__between {
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: space-between;
        }
      }

      &__button__text {
        font-family: 'Lato-Heavy';
        font-size: 2rem;
        margin-left: 1rem;
      }

      &__box {
        flex: 1;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border-radius: 0.5rem;
        padding: 1rem;
        border-top: 0.5rem solid #0a658e;
      }
    }

    &__photo__container {
      height: 25rem;
      overflow: hidden;
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      position: relative;

      &__edit {
        position: absolute;
        bottom: 0;
        left: 50%;
        color: white;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        background-color: #0a658e;
        transform: translateX(-50%);
        text-decoration: underline;
        cursor: pointer;
      }

      &__image {
        object-fit: cover;
        object-position: top;
        display: block;
        height: 100%;
        width: 100%;

        &__input {
          display: none;
        }
      }
    }
  }
  .time-picker-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 5px;

      .time-picker-label {
        font-weight: bold;
        margin-right: 1rem;
        color: white;
        text-overflow: ellipsis;
        font-family: 'Lato-Semibold';
        line-height: 100%;
        font-size: 16px;
      }
      .first {
        margin-left: auto;
      }
      div {
        width: fit-content;
        display: flex;
        margin-left: 70px;

        .time-picker-select {
          padding: 0.4rem;
          font-size: 1.2rem;
          border: 1px solid #ccc;
          border-radius: 0.5rem;
        }
      }
    }
  }
}

.right {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.left {
  flex: 2;
}
