.business__details__screen {
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  overflow: scroll;

  &__content {
    width: 90%;
    display: flex;
    flex-direction: column;

    &__container {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      gap: 2rem;
      padding: 20px;

      &__box {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border-radius: 0.5rem;
        padding: 1rem;
        border-top: 0.5rem solid #0a658e;
        margin-bottom: 2rem;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;

        &__row {
          margin-left: auto;
          margin-right: 2rem;

          &__buttons {
            display: flex;
            flex-direction: row;
            gap: 10px;
            // margin-top: 20px;
          }
        }
      }
    }
  }
  .time-picker-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 5px;

      .time-picker-label {
        font-weight: bold;
        margin-right: 1rem;
        color: white;
        text-overflow: ellipsis;
        font-family: 'Lato-Semibold';
        line-height: 100%;
        font-size: 16px;
      }
      .first {
        margin-left: auto;
      }
      div {
        width: fit-content;
        display: flex;
        margin-left: 70px;

        .time-picker-select {
          padding: 0.4rem;
          font-size: 1.2rem;
          border: 1px solid #ccc;
          border-radius: 0.5rem;
        }
      }
    }
  }
}
.property-dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;

  select {
    width: 100%;
    padding: 0.8rem;
    font-size: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    background-color: white;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    margin-top: 0.2rem;
    color: #333;
  }

  select:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }

  /* Hide the default arrow icon in IE and Edge */
  select::-ms-expand {
    display: none;
  }

  /* Style the dropdown arrow */
  select:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    pointer-events: none;
    border-style: solid;
    border-width: 0.3rem 0.3rem 0 0;
    border-color: #555 transparent transparent transparent;
  }
}

.property-dropdown label {
  color: white;
  margin-right: 10px;
}
.imageIconContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
.modalImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 350px;
  max-width: 500px;

  .iconContainer {
    position: absolute;
    top: -15px;
    right: -15px;
  }
}
