.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 4rem;
  z-index: 10;

  &__content {
    width: 100%;
    background-color: rgb(19, 25, 42);
    border: 1px solid #1b233b;
    border-radius: 0.8rem;
    // overflow: hidden;
    height: auto;
    &__inner {
      padding: 2rem;
    }
  }

  &__header {
    padding: 0.2rem 1rem;
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &-title {
      color: #f4f8fb;
      margin-top: 1.4rem;
    }
  }
}
