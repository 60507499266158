.error__screen {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      height: 10rem;
      width: 10rem;
      margin-bottom: 2rem;
    }

    & p {
      font-size: 3rem;
      color: #f8f8f8;
    }
  }
}
