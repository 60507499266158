.pagination-container {
  display: flex;

  .pagination-item {
    display: flex;
    align-items: center;
    color: #92929d;
    letter-spacing: 0.1px;
    font-size: 1.4rem;
    margin: 0 0.3rem;
    width: 3.2rem;
    justify-content: center;

    &.dots:hover {
      cursor: default;
    }
    &:hover {
      cursor: pointer;
    }

    &.selected {
      color: #074e6e;
      background-color: #074e6e28;
      border-radius: 5px;
    }
  }

  .arrow {
    display: flex;
    // border: 1px solid #44444f;
    background-color: #074e6e;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }
  }
}

.icon {
  padding: 0.4rem;
}
