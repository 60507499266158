.notification__screen {
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  overflow: scroll;

  $primary-color: #074e6e;
  $secondary-color: #6c757d;
  $light-color: #f8f9fa;

  .notification-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 20px;
    padding-left: 50px;
    padding-top: 20px;
    position: relative;

    &-input {
      margin-top: 20px;
      width: 350px;
      align-self: flex-start;
      position: fixed;
      top: 90px;
      left: 50px;

      &-checkbox_container {
        input[type='checkbox'] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          display: inline-block;
          width: 25px;
          height: 25px;
          background-clip: content-box;
          border: 1.5px solid #189ec1;
          border-radius: 6px;
          background-color: transparent;
          margin-left: 15px;
          margin-right: 15px;

          &:checked {
            background-color: #189ec1;
            border: 1.5px solid #8ccfe0;
          }

          &:focus {
            outline: none !important;
          }
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
        border-color: #189ec1;
        border-style: solid;
        border-radius: 10px;
        border-width: 1px;
        padding: 20px;
      }
    }

    .property-container {
      display: flex;
      flex-direction: row;
      margin-left: 20px;
      margin-right: 20px;
      align-self: flex-start;
      flex-wrap: wrap;
      gap: 20px;
    }

    .select-container {
      -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
      border-color: #189ec1;
      align-self: flex-start;
      border-style: solid;
      border-radius: 10px;
      border-width: 1.5px;
      padding: 20px;
      height: fit-content;
      min-width: 350px;
      max-width: 350px;
      margin-top: 20px;

      &:last-child {
        margin-right: 0;
      }

      &:after {
        content: '';
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        border-width: 5px 5px 0;
        border-color: $secondary-color transparent;
        border-style: solid;
      }

      &.is-open {
        &:after {
          border-width: 0 5px 5px;
          border-color: transparent $secondary-color;
        }
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      p {
        text-align: center;
      }

      h3 {
        text-align: center;
        font-weight: 400;
      }

      select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid $secondary-color;
        border-radius: 3px;
        background-color: $light-color;
        color: $secondary-color;
        padding: 10px;
        width: 250px;
        cursor: pointer;
      }
    }
  }
}
