.marketplace__screen {
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    text-align: left;
    flex: 1;
    &__btn {
      height: 3rem !important;
      width: 8rem !important;
    }
    &__search {
      display: flex;
      justify-content: center;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-title {
      font-family: 'Lato-Heavy';
      font-size: 4rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    &-search {
      margin-left: 3rem;
      margin-top: 2rem;
      max-width: 50rem;
    }
  }
}
