.tableImage {
  height: 10rem;
  cursor: pointer;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalImage {
  height: 45rem;
  max-width: 68rem;
}
