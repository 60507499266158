@font-face {
  font-family: 'Lato-Black';
  src: local('Lato-Black'), url(../assets/fonts/Lato-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato-Bold'), url(../assets/fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Heavy';
  src: local('Lato-Heavy'), url(../assets/fonts/Lato-Heavy.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Medium';
  src: local('Lato-Medium'), url(../assets/fonts/Lato-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  src: local('Lato-Regular'), url(../assets/fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Semibold';
  src: local('Lato-Semibold'), url(../assets/fonts/Lato-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'PTSerif-Bold';
  src: local('PTSerif-Bold'), url(../assets/fonts/PTSerif-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'PTSerif-Regular';
  src: local('PTSerif-Regular'), url(../assets/fonts/PTSerif-Regular.ttf) format('truetype');
}
