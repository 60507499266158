.buttonContainer {
  height: 5rem;
  border-radius: 0.5rem;
  background: linear-gradient(135deg, #189ec1 0%, #0a658e 100%);
  border: none;
  width: 100%;
  padding: 0 3rem;
  color: #ffffff;
  font-size: 2rem;
  font-family: 'Lato-Semibold';

  font-weight: 600;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;
}

.disabled {
  opacity: 0.54;
  cursor: not-allowed;
}
