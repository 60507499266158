.places-container {
  position: relative;
  .suggestions-list {
    position: absolute;
    background-color: white;
    border: 1px solid #189ec1;
    border-radius: 5px;
    opacity: 1;
    width: 100%;
    z-index: 10;
    top: 50px;
    left: 0;
    .sugestion {
      margin-bottom: 2px;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        background-color: #74c5da;
      }
    }
  }
}

.google-map {
  height: 400px;
  width: 100%;
  margin: 1rem 0;
  border-radius: 0.5rem;
  overflow: hidden;

  .map__marker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    border: 0.25rem solid #fff;
  }

  .map__pin {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    z-index: 1;
  }

  .map__info {
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1rem;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -0.5rem);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0.5rem 0.5rem 0 0.5rem;
      border-color: #fff transparent transparent transparent;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
