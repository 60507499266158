.media__slider__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__icon_arrow {
    height: 4rem;
    width: 4rem;
  }
}
