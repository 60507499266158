.imageLoader {
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  flex: 1;
}

.loader {
  width: 5rem;
  height: 5rem;
  margin: unset !important;
}
