.custom-field {
  position: relative;
  width: 100%;
  font-size: 14px;
  margin-bottom: 2rem;
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #189ec1;
  outline: none;
  font-size: 16px;
  padding: 0 16px;
  width: 100%;
}

.custom-field textarea {
  border: none;
  resize: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #189ec1;
  outline: none;
  font-size: 16px;
  padding: 16px;
  width: 100%;
}

.custom-field .placeholder {
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Lato-Semibold';

  line-height: 100%;
  color: #189ec1;
  font-size: 16px;
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
  padding: 0 3px;
}

.custom-field input.dirty + .placeholder,
.custom-field input:focus + .placeholder,
.custom-field input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 13px;
  background-color: transparent;
  color: #0a658e;
}

.custom-field textarea.dirty + .placeholder,
.custom-field textarea:focus + .placeholder,
.custom-field textarea:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 13px;
  background-color: transparent;
  color: #0a658e;
}

.custom-field input.dirty,
.custom-field input:focus,
.custom-field input:not(:placeholder-shown) {
  background-color: transparent;
  border: 1px solid #0a658e;
  border-radius: 5px;
  color: #0a658e;
}

.custom-field textarea.dirty,
.custom-field textarea:focus,
.custom-field textarea:not(:placeholder-shown) {
  background-color: transparent;
  border: 1px solid #0a658e;
  border-radius: 5px;
  color: #0a658e;
}

.custom-field-em-phone-number input:not(:placeholder-shown) {
  position: relative;
  width: 100%;
  font-size: 14px;
  margin-bottom: 2rem;
  border: 1px solid #0a658e;
  border-radius: 5px;
  color: #0a658e;
}

.custom-field-em-phone-number textarea:not(:placeholder-shown) {
  position: relative;
  width: 100%;
  font-size: 14px;
  margin-bottom: 2rem;
  border: 1px solid #0a658e;
  border-radius: 5px;
  color: #0a658e;
}
