.table__wrapper {
  border: 1px solid #074e6e;
  border-radius: 5px;
  width: 100%;
  flex: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 2rem;
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    top: 0;
    position: sticky;
    background-color: white;

    tr {
      background: #074e6e;

      th {
        color: white;
        text-align: left;
        font-size: 1.8rem;
        font-family: 'Lato-Medium';
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        padding-left: 1rem;
      }
    }
  }

  .tbody {
    .tr {
      // height: 69px;
      &:nth-child(2n) {
        background: #074e6e28;
      }

      &:last-of-type {
        td {
          border-bottom: none;
        }
      }

      td {
        padding: 1rem;
      }
    }
  }

  //   .th,
  //   .td {
  //     text-align: left;
  //     color: black;
  //     font-weight: 500;
  //     font-size: 1.2rem;
  //     white-space: nowrap;
  //     border-bottom: 0.5px solid #bfbfbf;

  //     &:last-of-type {
  //       //   text-align: right;
  //     }

  //     &:nth-of-type(3) {
  //       min-width: rem(150px);
  //     }
  //   }

  .th {
    padding: rem(22px) rem(20px) rem(10px);

    &:last-of-type {
      //   text-align: right;
    }

    &.sortable {
      color: white;
      cursor: pointer;
    }

    &.sorted {
      color: #9acd32;
    }
  }

  .td {
    padding: rem(17px) rem(20px);
    word-break: break-all;
    min-width: 260px;

    &:first-of-type {
      padding: rem(17px) rem(5px) rem(17px) rem(20px);
    }

    &:last-of-type {
      min-width: 0;
    }

    &:nth-of-type(2) {
      word-break: break-all;
    }

    &:nth-of-type(6) {
      text-transform: capitalize;
    }
  }
}

.editIcon {
  width: rem(16px);
  cursor: pointer;
}

.actionButton {
  background-color: transparent;
  border: none;
}

.typeField {
  text-transform: capitalize;
}

.noDataTd {
  padding: rem(17px) rem(20px);
  white-space: nowrap;
  font-weight: 600;
  text-align: center;
}

.tdContentFlexCenter {
  display: flex;
  align-items: center;
}

.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: '';
  display: inline-block;
  height: 1rem;
  left: 1rem;
  position: relative;
  vertical-align: top;
  width: 1rem;
  top: 1rem;
}

.chevron.bottom:before {
  transform: rotate(135deg);
}

.chevron.top:before {
  top: 1rem;
  transform: rotate(-45deg);
}

:root {
  --select-border: #e8eaed;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

.pagination__container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 3rem;
}
