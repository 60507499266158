.pulse {
  animation: pulse 1s infinite;
  margin: 0 auto;
  display: table;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;

  &__frame {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #189ec1;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.12);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.12);
  }
  100% {
    transform: scale(1);
  }
}
