.admins__screen {
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  align-items: center;

  $primary-color: #074e6e;
  $secondary-color: #6c757d;
  $light-color: #f8f9fa;

  &__content {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    text-align: left;
    flex: 1;
    &__btn {
      height: 3rem !important;
      width: 8rem !important;
    }
    &__search {
      display: flex;
      justify-content: center;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-title {
      font-family: 'Lato-Heavy';
      font-size: 4rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    &-search {
      margin-left: 3rem;
      margin-top: 2rem;
      max-width: 50rem;
    }

    &-iconContainer {
      margin-left: auto;
      justify-content: center;
      align-items: center;
    }
    &-icon {
      height: 5rem;
      width: 5rem;
      align-self: center;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }

  .property-dropdown {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;

    select {
      width: 100%;
      padding: 0.8rem;
      font-size: 1.2rem;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      background-color: white;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      margin-top: 0.2rem;
      color: #333;
    }

    select:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

    /* Hide the default arrow icon in IE and Edge */
    select::-ms-expand {
      display: none;
    }

    /* Style the dropdown arrow */
    select:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      pointer-events: none;
      border-style: solid;
      border-width: 0.3rem 0.3rem 0 0;
      border-color: #555 transparent transparent transparent;
    }
  }
  .select-container {
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    &:last-child {
      margin-right: 0;
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-width: 5px 5px 0;
      border-color: $secondary-color transparent;
      border-style: solid;
    }

    &.is-open {
      &:after {
        border-width: 0 5px 5px;
        border-color: transparent $secondary-color;
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    span {
      color: #f8f9fa;
    }

    select {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid $secondary-color;
      border-radius: 3px;
      background-color: $light-color;
      color: $secondary-color;
      padding: 10px;
      width: 350px;
      cursor: pointer;
    }
  }
}
