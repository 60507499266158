.business__screen {
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    text-align: left;
    flex: 1;
    &__btn {
      height: 3rem !important;
      width: 8rem !important;
    }
    &__search {
      display: flex;
      justify-content: center;
    }

    &__photo__container {
      height: 20rem;
      overflow: hidden;
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      position: relative;

      &__edit {
        position: absolute;
        bottom: 0;
        left: 50%;
        color: white;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        background-color: #0a658e;
        transform: translateX(-50%);
        text-decoration: underline;
        cursor: pointer;
      }

      &__image {
        object-fit: cover;
        object-position: top;
        display: block;
        height: 100%;
        width: 100%;

        &__input {
          display: none;
        }
      }
    }

    .property-dropdown {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;

      select {
        width: 100%;
        padding: 0.8rem;
        font-size: 1.2rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        background-color: white;
        cursor: pointer;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        margin-top: 0.2rem;
        color: #333;
      }

      select:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      }

      /* Hide the default arrow icon in IE and Edge */
      select::-ms-expand {
        display: none;
      }

      /* Style the dropdown arrow */
      select:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        pointer-events: none;
        border-style: solid;
        border-width: 0.3rem 0.3rem 0 0;
        border-color: #555 transparent transparent transparent;
      }
    }

    .time-picker-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      margin-top: 1rem;

      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 5px;

        .time-picker-label {
          font-weight: bold;
          margin-right: 1rem;
          color: white;
          text-overflow: ellipsis;
          font-family: 'Lato-Semibold';
          line-height: 100%;
          font-size: 16px;
        }
        .first {
          margin-left: auto;
        }
        div {
          width: fit-content;
          display: flex;
          margin-left: 70px;

          .time-picker-select {
            padding: 0.4rem;
            font-size: 1.2rem;
            border: 1px solid #ccc;
            border-radius: 0.5rem;
          }
        }
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-title {
      font-family: 'Lato-Heavy';
      font-size: 4rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    &-search {
      margin-left: 3rem;
      margin-top: 2rem;
      max-width: 50rem;
    }
    &-iconContainer {
      margin-left: auto;
      justify-content: center;
      align-items: center;
    }
    &-icon {
      height: 5rem;
      width: 5rem;
      align-self: center;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }
}

.property-dropdown label {
  color: white;
  margin-right: 10px;
}

.imageIconContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
.modalImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 350px;
  max-width: 500px;

  .iconContainer {
    position: relative;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    .icon {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 32px;
      height: 32px;
    }
  }
}
