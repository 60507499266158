.marketplace__item__screen {
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  overflow: scroll;

  &__content {
    width: 90%;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__container {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      gap: 2rem;
      position: relative;

      &__box {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border-radius: 0.5rem;
        padding: 1rem;
        border-top: 0.5rem solid #0a658e;
        margin-bottom: 2rem;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;

        &__delete-container {
          position: absolute;
          top: 2rem;
          right: 2rem;
          cursor: pointer;
        }

        &__row {
          margin-left: auto;
          margin-right: 2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;

          &__buttons {
            display: flex;
            flex-direction: row;
            gap: 10px;
          }
        }
      }
    }
  }
  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modalImage {
    height: 45rem;
    max-width: 68rem;
  }
}
