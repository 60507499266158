.admin__details__screen {
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  overflow: scroll;

  $primary-color: #074e6e;
  $secondary-color: #6c757d;
  $light-color: #f8f9fa;

  &__content {
    width: 90%;
    display: flex;
    flex-direction: column;

    &__container {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      gap: 2rem;
      padding: 20px;

      &__box {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border-radius: 0.5rem;
        padding: 1rem;
        border-top: 0.5rem solid #0a658e;
        margin-bottom: 2rem;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;

        &__column {
          margin-left: auto;
          margin-right: 2rem;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  .property-dropdown {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;

    select {
      width: 100%;
      padding: 0.8rem;
      font-size: 1.2rem;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      background-color: white;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      margin-top: 0.2rem;
      color: #333;
    }

    select:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

    /* Hide the default arrow icon in IE and Edge */
    select::-ms-expand {
      display: none;
    }

    /* Style the dropdown arrow */
    select:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      pointer-events: none;
      border-style: solid;
      border-width: 0.3rem 0.3rem 0 0;
      border-color: #555 transparent transparent transparent;
    }
  }
  .select-container {
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    // height: fit-content;
    width: 100%;
    margin-top: 20px;

    &:last-child {
      margin-right: 0;
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-width: 5px 5px 0;
      border-color: $secondary-color transparent;
      border-style: solid;
    }

    &.is-open {
      &:after {
        border-width: 0 5px 5px;
        border-color: transparent $secondary-color;
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    span {
      color: #f8f9fa;
    }

    select {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid $secondary-color;
      border-radius: 3px;
      background-color: $light-color;
      color: $secondary-color;
      padding: 10px;
      width: 350px;
      cursor: pointer;
    }
  }
}
